import React from "react";

import "./blog.css";

export const Card = ({ post }) => {
  return (
    <div className="post_content_wrapper">
      <a href={`/blog/${post.slug?.current}`} className="post_card">
        {post.mainImage && (
          <img
            src={post.mainImage && post.mainImage.asset.url}
            alt={post.mainImage && post.mainImage.alt}
          />
        )}
        <div className="post_content">
          <h4 className="post_publish_date">
            {post.publishedAt && post.publishedAt.slice(0, 10)}
          </h4>
          <h2 className="post_title">{post.title}</h2>
        </div>
      </a>
    </div>
  );
};
