import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../sanityClient";

import "./singlePost.css";

const SinglePost = () => {
  const [postData, setPostData] = useState("");
  const slug = useParams().blog;
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post' && slug.current == "${slug}"]{
        title,
        slug,
        body, publishedAt,
        mainImage{
          asset->{
            _id,
            url
          },
          alt
        }
      }`
      )
      .then((data) => setPostData(data))
      .catch((err) => console.log(err));
  }, [slug]);

  return (
    <>
      <div className="post_wrapper">
        {postData ? (
          <div>
            {postData.map((data, i) => (
              <div key={i}>
                <h4 className="post_publish_date">
                  {data.publishedAt && data.publishedAt.slice(0, 10)}
                </h4>
                <h2 className="title">{data.title}</h2>
                <div className="image_wrapper">
                  {data.mainImage && (
                    <img
                      src={data.mainImage && data.mainImage.asset.url}
                      alt={data.mainImage && data.mainImage.alt}
                    />
                  )}
                </div>
                {data.body &&
                  data.body.map((e) =>
                    e.children.map((child, i) => <p key={i}>{child.text}</p>)
                  )}
                <div className="end_div">
                  <p className="dots">...</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="single_post_data_loading_skeleton">
            <div className="single_post_data_loading_skeleton_date"></div>
            <div className="single_post_data_loading_skeleton_title"></div>
            <div className="single_post_data_loading_skeleton_img"></div>
            <div className="single_post_data_loading_skeleton_content"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default SinglePost;
