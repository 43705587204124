import React from "react";

import { BlogList } from "../Blog";

import "./home.css";

const Home = () => {
  return <BlogList />;
};

export default Home;
