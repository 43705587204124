import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import SinglePost from "./components/SinglePost/SinglePost";
import Footer from "./components/Footer";

import "./style/_color.css";
import "./style/_base.css";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog/">
          <Route path=":blog" element={<SinglePost />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
