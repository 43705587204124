import Page from "../Page";

import "./footer.css";

function Footer() {
  return (
    <footer className="footer_wrapper">
      <Page>
        <div className="display_flex footer_main_wrapper">
          <a href="/">
            <img
              className="footer_logo"
              src={require("../../images/VgroomUBlogs.png")}
              alt=" VGroomU logo"
            />
          </a>
          <div className="footer_content_wrapper display_flex">
            <div className="footer_details_wrapper footer_details_wrapper_contact">
              <div className="footer_details footer_details_wrapper_contact">
                <h6 className="footer_details_title">Contact</h6>
                <a
                  href="tel:+919145694434"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6 className="footer_details_title footer_details_title_mobile">
                    +91 9145 694 434
                  </h6>
                </a>
                <a
                  href="mailto:contact-us@easemysalon.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="contact_content">contact-us@vgroomu.com</p>
                </a>
                <p className="contact_content">
                  Hinjewadi phase 3 Pune, Pune 411057
                </p>
                <div className="display_flex">
                  <a
                    href="https://www.facebook.com/vgroomu"
                    target="_blank"
                    rel="noreferrer"
                    className="contact_links_logo"
                  >
                    <img
                      className="contact_links_logo"
                      src={require("../../images/facebook.png")}
                      alt="VGroomU facebook"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/vgroomu/"
                    target="_blank"
                    rel="noreferrer"
                    className="contact_links_logo"
                  >
                    <img
                      className="contact_links_logo"
                      src={require("../../images/instagram.png")}
                      alt="VGroomU instagram"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCt_p01wW6_rK7MB6EQuicKQ"
                    target="_blank"
                    rel="noreferrer"
                    className="contact_links_logo"
                  >
                    <img
                      className="contact_links_logo"
                      src={require("../../images/youtube.png")}
                      alt="VGroomU youtube"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/we-groom-you/"
                    target="_blank"
                    rel="noreferrer"
                    className="contact_links_logo"
                  >
                    <img
                      className="contact_links_logo"
                      src={require("../../images/linkedin.png")}
                      alt="VGroomU linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer_details_wrapper">
              <div className="footer_details">
                <h6 className="footer_details_title">Company</h6>
                <a
                  href="https://www.vgroomu.com/about"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p>About</p>
                </a>
                <a
                  href="https://www.vgroomu.com/partner"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p>Partner</p>
                </a>

                <a
                  href="https://www.vgroomu.com/terms"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p className="footer_link_content">Terms & Agreements</p>
                </a>
                <a
                  href="https://www.vgroomu.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p>Privacy Policy</p>
                </a>
              </div>
            </div>
            <div className="footer_details_wrapper">
              <div className="footer_details">
                <h6 className="footer_details_title">Support</h6>
                <a
                  href="https://www.vgroomu.com/faqs"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p>FAQs</p>
                </a>
                <a
                  href="https://www.vgroomu.co.in/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p>Blog</p>
                </a>

                <a
                  href="https://www.vgroomu.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p>Support Center</p>
                </a>
                <a
                  href="https://www.vgroomu.com/pricing"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_link"
                >
                  <p>Plan & Pricing</p>
                </a>
              </div>
            </div>
          </div>
          <div className="footer_rights display_flex">
            <div className="color_bar"></div>
            <p>© 2023 VgroomU. All rights reserved.</p>
          </div>
        </div>
      </Page>
    </footer>
  );
}

export default Footer;
