import React, { useState, useEffect } from "react";
import Page from "../Page";
import "./header.css";

function Header() {
  const [navOpen, setNavOpen] = useState(false);
  const [navColourChange, setNavColourChange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 40) {
      setNavColourChange(true);
    } else {
      setNavColourChange(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => window.removeEventListener("scroll", changeNavbarColor);
  });

  return (
    <header
      className={
        navColourChange ? "header_wrapper color_Change" : "header_wrapper"
      }
    >
      <Page>
        <nav className="display_flex nav">
          <div className="logo_wrapper">
            <a href="/">
              <img
                src={require("../../images/VgroomUBlogs.png")}
                alt=" VGroomU logo"
              />
            </a>
          </div>
          <div className="mobile_nav_content">
            <button
              className="hamburger_icon"
              onClick={() => setNavOpen(!navOpen)}
            >
              {navOpen ? (
                <img
                  src={require("../../images/close.png")}
                  alt="VGroomU close"
                />
              ) : (
                <img
                  src={require("../../images/menu.png")}
                  alt="VGroomU open"
                />
              )}
            </button>
            {navOpen ? (
              <>
                <div className="mobile_nav">
                  <div className="mobile_nav_links_wrapper display_flex">
                    <a href="/" className="links">
                      Home
                    </a>
                    <a
                      href="https://www.vgroomu.com/about"
                      target="_blank"
                      rel="noreferrer"
                      className="links"
                    >
                      About Us
                    </a>
                    <a
                      href="https://www.vgroomu.com/partner"
                      target="_blank"
                      rel="noreferrer"
                      className="links"
                    >
                      Partner
                    </a>
                    <a
                      href="https://www.vgroomu.co.in/"
                      target="_blank"
                      rel="noreferrer"
                      className="links"
                    >
                      Blogs
                    </a>
                  </div>
                </div>
                <div
                  onClick={() => setNavOpen(false)}
                  className="mobile_nav_wrapper"
                ></div>
              </>
            ) : null}
          </div>
          <div className="content_wrapper display_flex">
            <div className="display_flex links_wrapper">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a
                    href="https://www.vgroomu.com/about"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.vgroomu.com/partner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Partner
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.vgroomu.co.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blogs
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </Page>
    </header>
  );
}

export default Header;
