import React, { useState, useEffect } from "react";

import sanityClient from "../../sanityClient";
import { Card } from "./Card";

import "./blog.css";

export const BlogList = () => {
  const [postData, setPostData] = useState("");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
            title,
            slug,
            body,
            publishedAt,author,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            }
          }`
      )
      .then((data) => setPostData(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <h2 className="page_heading">Dive Into Our Blog</h2>
      <div className="posts_wrapper">
        {postData
          ? postData.map((post, index) => <Card key={index} post={post} />)
          : Array(3)
              .fill()
              .map((data, index) => {
                return (
                  <div key={index} className="post_data_loading_skeleton">
                    <div className="post_data_loading_skeleton_img"></div>
                    <div className="post_data_loading_skeleton_content"></div>
                  </div>
                );
              })}
      </div>
    </>
  );
};
